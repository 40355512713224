const DateFormat = (dateString: string, options = {}) => {
  if (!dateString) {
    return;
  }
  if (!Object.keys(options).length) {
    options = { day: 'numeric', month: 'long', year: 'numeric' };
  }

  const date = new Date(dateString);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return date.toLocaleDateString('fr-FR', options);
};

export default DateFormat;
